@use '_colors' as *;

.custom-selector {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border: 1px solid $pattens-blue;
  border-radius: 4px;
  padding-right: 48px;

  &[disabled] {
    background-color: $alice-blue;

    .custom-selector_button {
      cursor: default;
    }

    .custom-selector_button {
      background: $heather;
    }
  }
}

.custom-selector_text {
  display: flex;
  flex-grow: 1;
  padding: 14px 16px;
  height: 100%;

  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  .custom-selector_placeholder {
    color: $heather;
    user-select: none;
  }
}

.custom-selector_button {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blue;
  width: 48px;
  height: 100%;
}

.has-error {
  .custom-selector {
    border-color: $error;
  }
}
