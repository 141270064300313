@use 'styles/animations';
@use 'styles/main.scss';
@use 'styles/helpers.scss';
@use 'styles/forms.scss';
@use 'styles/modals.scss';
@use 'styles/buttons.scss';
@use 'styles/carousel.scss';
@use 'styles/loader.scss';
@use 'styles/detailed-info.scss';
@use 'styles/tabs.scss';
@use 'styles/dropdown.scss';
@use 'styles/notifications.scss';
@use 'styles/folders.scss';
@use 'styles/custom-selector.scss';
@use 'styles/doka.scss';
@use 'styles/hellosign-styles.scss';
@use 'styles/icons.scss';
@use 'styles/fonts';
@use 'styles/markdown';
@use 'styles/datepicker.scss';
@use 'styles/tour.scss';

@use 'app/auth/auth.scss';

@use 'styles/flexmonster-override.scss';

body:has(.cdk-drag-preview) {
  cursor: grabbing !important;
}

.clover-modal-backdrop {
  opacity: 0.5 !important;
  background: var(--comp-menu-item-color-text, #25292e);
}

.str-chat__load-more-button {
  display: none;
}

.str-chat__fileupload-wrapper,
.str-chat__send-button {
  display: none;
}

.str-chat__input-flat {
  display: flex;
  flex: 1 0 auto;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #08a262;
}

.ant-progress-circle-trail {
  stroke: #dcdfe3;
}

a {
  text-decoration: none !important;
}

ngb-modal-backdrop.clover-priority-overlay-panel {
  z-index: 99998 !important;
}

.cdk-global-overlay-wrapper:has(.clover-toast-panel),
.cdk-global-overlay-wrapper:has(.clover-priority-overlay-panel),
ngb-modal-window.clover-priority-overlay-panel,
.cdk-overlay-container:has(.clover-priority-overlay-panel),
.clover-priority-overlay-backdrop {
  z-index: 99999;
}

.cdk-overlay-container {
  z-index: 2000;

  .cc-overlay-hidden {
    display: none;
  }
}

.cc-full-height {
  height: 100%;
}

.cc-smooth-entry {
  animation: smooth-entry 0.2s ease-in-out;

  @keyframes smooth-entry {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
}

body:has(.app-loader-overlay) {
  .page__subnav-preview {
    display: none;
  }
}

.cc-placeholder-text {
  opacity: 0.5;
}
