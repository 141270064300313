@use '_colors' as *;

ngb-carousel {
  outline: none;
}

.carousel-inner {
  padding-bottom: 38px;
}

.carousel-indicators {
  margin: 0;

  li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: none;
  }
}

.carousel-caption {
  position: static;
  padding: 0;
  margin-top: 60px;

  > h2 {
    font-size: 30px;
    color: $white;
    line-height: 41px;
    padding: 0;
    margin: 0 0 8px;
    text-align: center;
  }

  > p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin: 0;
    color: $white;
  }
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
}

.carousel-img-wrapper {
  width: 80%;
  margin: auto;

  img {
    width: 100%;
  }
}
