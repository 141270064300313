@use '_colors' as *;

ngb-datepicker {
  padding: 10px 20px !important;

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-weekday {
    flex-grow: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    height: 14px;
    text-transform: uppercase;
    color: $heather;
  }

  .ngb-dp-arrow > button {
    color: $midnight;
  }

  .ngb-dp-header,
  .ngb-dp-weekdays {
    border: none;
    background: transparent;
  }

  .ngb-dp-weekdays {
    margin-bottom: 10px;
  }

  .ngb-dp-day {
    flex-grow: 1;

    > div {
      color: $midnight;
      width: 100% !important;
    }

    > .btn-light:hover {
      border-color: $heather;
      background-color: $heather;
    }

    > .bg-primary {
      background: $blue !important;
    }
  }

  ngb-datepicker-navigation {
    padding: 14px 12px;
  }

  ngb-datepicker-navigation-select > select {
    color: $midnight !important;
    width: auto !important;
    margin: 0 5px;
    cursor: pointer;
  }
}
