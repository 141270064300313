@use '_colors' as *;

#toast-container {
  top: 72px;
  right: 16px;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 72px;
    padding-right: 40px;
    width: 420px;
    min-height: 56px;
    background-image: none !important;
    background-color: $white;
    box-shadow:
      0px 8px 18px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.1),
      0px 8px 24px rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-position: center;
      width: 56px;
      height: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      right: 16px;
      top: calc(50% - 6px);
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/svg/common/cross.svg');
      background-size: contain;
    }
  }

  .toast-message {
    color: $midnight;
    font-size: 16px;
    line-height: 18px;
  }

  .toast-success {
    border: 1px solid $success;
    &:before {
      background-color: $success;
      background-image: url('../assets/svg/notifications/success.svg');
    }
  }

  .toast-error {
    border: 1px solid $error;
    &:before {
      background-color: $error;
      background-image: url('../assets/svg/notifications/error.svg');
    }
  }

  .toast-warning {
    border: 1px solid $warning;
    &:before {
      background-color: $warning;
      background-image: url('../assets/svg/notifications/warning.svg');
    }
  }
}
