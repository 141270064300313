@use '_colors' as *;

.folders {
  width: 300px;
  background: $alice-blue;
  padding: 28px 31px;
  flex-shrink: 0;

  > h1 {
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 800px) {
  .folders {
    width: 250px;
  }
}

.folders-container {
  margin: 10px 0;
}

.folders-title {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:not(:first-of-type) {
    margin-top: 60px;
  }
}

.folder {
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 10px 0;
  border-radius: 4px;
  min-height: 40px;

  &.dragging-over {
    border: 1px dashed $blue;
    padding: 7px;
  }

  &.selected {
    background: $solitude;

    .folder-name {
      font-weight: 500;
      color: $blue;
    }
  }

  &:hover,
  &:focus {
    .folder-actions .dropdown-toggle {
      visibility: visible;
    }
  }
}

.folder-name {
  font-size: 16px;
  line-height: 16px;
  word-break: break-word;
  margin: 0 9px;
  flex-grow: 1;
}

.folder-action {
  visibility: hidden;
}

.folder-actions {
  .dropdown-toggle {
    visibility: hidden;
    width: 18px;
    height: 22px;
    background-image: url('../assets/svg/files/ellipsis-grey.svg');

    &:hover,
    &:focus {
      background-image: url('../assets/svg/files/ellipsis-blue.svg');
    }

    &:focus {
      visibility: visible;
    }
  }
}

.folders_add-folder-btn {
  color: $blue;
  font-size: 14px;
  line-height: 24px;

  &:hover {
    font-weight: 600;
  }
}
