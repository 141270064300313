.cc-tour-popover {
  padding: 24px;
  width: 400px;
  max-width: 90vw;
  max-height: 90vh;

  border-radius: 6px;
  background: var(--core-color-bg-surface, #fff);
  box-shadow:
    0 3px 5px 0 rgba(0, 0, 0, 0.06),
    0 9px 16px 0 rgba(0, 0, 0, 0.1),
    0 42px 70px 0 rgba(0, 0, 0, 0.16);

  .driver-popover-close-btn {
    width: 32px !important;
    height: 32px !important;
    border-radius: 6px;
    top: 20px !important;
    right: 18px !important;

    i {
      pointer-events: none;
      font-size: 18px;
      color: var(--core-color-fg-medium, #4b545d);
    }

    &:hover {
      background: var(--comp-button-color-ghost-bg-hover);
      color: var(--comp-button-color-ghost-icon-hover);
    }

    &:active {
      background: var(--comp-button-color-ghost-bg-pressed);
      color: var(--comp-button-color-ghost-icon-hover);
    }
  }

  .driver-popover-title {
    &,
    & * {
      font-family: Inter, sans-serif;
      font-style: normal;
      color: var(--comp-modal-color-fg-strong, #25292e);
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.54px;
    }
  }

  .driver-popover-description {
    font-weight: 400;
    margin-top: 16px !important;

    &,
    & * {
      font-family: Inter, sans-serif;
      font-style: normal;
      color: var(--core-color-fg-medium, #565e66);
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.42px;
    }
  }

  .driver-popover-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px !important;

    .driver-popover-progress-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      color: var(--comp-drawer-color-fg-weak, #8d969f);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.42px;
    }

    .driver-popover-navigation-btns {
      display: flex;
      gap: 8px;

      .driver-popover-prev-btn,
      .driver-popover-postpone-btn,
      .driver-popover-next-btn {
        padding: 0 10px !important;
        height: 32px;
        margin: 0 !important;
        text-shadow: none !important;
        border: none !important;

        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        letter-spacing: -0.01em;
      }

      .driver-popover-prev-btn,
      .driver-popover-postpone-btn {
        color: var(--comp-button-color-secondary-text);
        background: var(--comp-button-color-secondary-bg-enabled);
        border: 1px solid var(--comp-button-color-secondary-border) !important;
        box-shadow:
          0 2px 12px rgba(0, 0, 0, 0.06),
          0 1px 2px rgba(0, 0, 0, 0.12);

        &:hover {
          background: var(--comp-button-color-secondary-bg-hover);
        }

        &:active {
          background: var(--comp-button-color-secondary-bg-pressed);
        }

        &:disabled {
          border-color: var(--comp-button-color-secondary-border-disabled);
          color: var(--comp-button-color-secondary-text-disabled);
        }
      }

      .driver-popover-next-btn {
        color: var(--comp-button-color-primary-text);
        background: var(--comp-button-color-primary-bg-enabled);

        &:hover {
          background: var(--comp-button-color-primary-bg-hover);
        }

        &:active {
          background: var(--comp-button-color-primary-bg-pressed);
        }

        &:disabled {
          background: var(--comp-button-color-primary-bg-disabled);
        }
      }
    }
  }
}
